import React from 'react'
import PropTypes from 'prop-types'

import { getTypeKey } from '@helpers/strapi'
import { graphql } from 'gatsby'

import PageRecentlyViewed from '@shared/recently-viewed'
import PageMarkdown from './Markdown'
import PageBanner from './Banner'
import PageSearchQuery from './SearchQuery'
import PageButtonList from './ButtonList'
import PageGrid from './Grid'
import PageSlider from './SliderWrapper'
import PageAccordion from './Accordion'
import PageAccordionList from './AccordionList'
import PageHtml from './HTML'
import ContentGroup from './ContentGroup'
import PageDeliveryWidget from './DeliveryWidget'
import PageImageList from './ImageList'
import PageSpecial from './Specials'
import PageVideo from './Video'
import PageTabs from './Tabs/Tabs'
import PageTileGrid from './TileGrid'
import PageSliderFilterList from './SliderFilterList'
import PageImageGallery from './ImageGallery/ImageGallery'
import BaseHeader from './Header'
import PageBannerSwiper from './BannerSwiper/BannerSwiper'
import PageMosaicGrid from './MosaicGrid/MosaicGrid'
import PageFixedBannerSwiper from './FixedBannerSwiper/FixedBannerSwiper'

const StrapiMappings = {
  BaseHeader,
  ContentGroup,
  PageAccordion,
  PageAccordionList,
  PageBanner,
  PageBannerSwiper,
  PageButtonList,
  PageDeliveryWidget,
  PageGrid,
  PageHtml,
  PageImageGallery,
  PageImageList,
  PageMarkdown,
  PageRecentlyViewed,
  PageSearchQuery,
  PageSlider,
  PageSliderFilterList,
  PageSpecial,
  PageTabs,
  PageTileGrid,
  PageVideo,
  PageMosaicGrid,
  PageFixedBannerSwiper,
}

const CreateContent = (contentSection, index, horizontalSpacing) => {
  const { Title, Content } = contentSection || {}
  return contentSection ? (
    <>
      {Content &&
        Array.isArray(Content) &&
        Content.map((subContent, subIndex) => {
          if (!subContent?.__typename) {
            return null
          }
          if (subContent.__typename === 'PageContentGroup') {
            return (
              <ContentGroup
                data={subContent?.ContentGroup}
                key={getTypeKey(subContent, 'content-section-')}
                index={index}
                horizontalSpacing={horizontalSpacing}
              />
            )
          }
          const Component = StrapiMappings[subContent?.__typename]
          return Component ? (
            <Component
              // eslint-disable-next-line react/destructuring-assignment
              data={{ ...subContent, contentSectionTitle: Title }}
              contentIndex={`${index}:${subIndex}`}
              key={getTypeKey(subContent, 'content-section-')}
            />
          ) : null
        })}
    </>
  ) : null
}

export function ContentSection({ data, index = 0, horizontalSpacing = { LeftPadding: 0, RightPadding: 0 } }) {
  const contentSection = data?.strapiContentSection ?? data?.contentSection ?? data ?? {}
  return contentSection && CreateContent(contentSection, index, horizontalSpacing)
}

ContentSection.propTypes = {
  data: PropTypes.object,
  horizontalSpacing: PropTypes.object,
}

export const StrapiContentSectionFragment = graphql`
  fragment StrapiContentSectionFragment on StrapiContentSection {
    id
    testId
    Title
    Content {
      __typename
      ... on PageImageList {
        ImageList {
          ...StrapiImageListFragment
        }
      }
      ... on PageBanner {
        __typename
        Banner {
          ...StrapiPageBannerFragment
        }
      }
      ... on PageMarkdown {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      ... on PageButtonList {
        ButtonList {
          ...StrapiButtonListFragent
        }
      }
      ... on PageGrid {
        __typename
        Grid {
          ...StrapiPageGridFragment
        }
      }
      ... on PageSlider {
        Slider {
          ...StrapiSliderfragment
        }
      }
      ... on PageContentGroup {
        ContentGroup {
          ...StrapiContentGroupFragment
        }
      }
      ... on PageTabs {
        TabGroup {
          ...StrapiPageTabGroupFragment
        }
      }
      ... on PageAccordion {
        Accordion {
          ...StrapiPageAccordionFragment
        }
      }
      ... on PageAccordionList {
        AccordionList {
          ...StrapiPageAccordionListFragment
        }
      }
      ... on PageRecentlyViewed {
        ShowRecentlyViewed
      }
      ... on PageHtml {
        HTML {
          ...StrapiHtmlFragment
        }
      }
      ... on PageDeliveryWidget {
        ...DeliveryWidgetFragment
      }
      ... on PageSpecial {
        Specials {
          ...StrapiSpecialsFragment
        }
      }
      ... on PageVideo {
        Video {
          ...StrapiPageVideoFragment
        }
      }
      ... on PageTileGrid {
        TileGrid {
          ...StrapiTileGridFragment
        }
      }
      ... on PageSliderFilterList {
        SliderFilterList {
          ...SliderFilterListFragment
        }
      }
      ... on PageImageGallery {
        ImageGallery {
          ...ImageGalleryFragment
        }
      }
      ... on PageSearchQuery {
        SearchQuery {
          ...SearchQueryStrapiFragment
        }
      }
      ... on BaseHeader {
        ...StrapiHeaderFragment
      }
      ... on PageBannerSwiper {
        BannerSwiper {
          ...StrapiPageBannerSwiperFragment
        }
      }
      ... on PageMosaicGrid {
        MosaicGrid {
          ...StrapiMosaicGridFragment
        }
      }
      ... on PageFixedBannerSwiper {
        FixedBannerSwiper {
          ...FixedBannerSwiperFragment
        }
      }
    }
  }
`

export default ContentSection
